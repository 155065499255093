
import Vue from "vue";

export default Vue.extend({
  name: "Imprint",
  data: () => ({
    itemsImprintAddress: [
      {
        description: "Firmenname",
        name: "TÜV | DEKRA arge tp 21 GmbH",
        link: ""
      }, {
        description: "Adresse",
        name: "Wintergartenstraße 4, 01307 Dresden",
        link: ""
      }, {
        description: "Geschäftsführer",
        name: "Dipl.-Ing. Mathias Rüdel",
        link: ""
      }, {
        description: "Telefon",
        name: "+49 351 20789-0",
        link: ""
      }, {
        description: "Fax",
        name: "+49 351 20789-20",
        link: ""
      }, {
        description: "Internet",
        name: "https://argetp21.de",
        link: "https://argetp21.de"
      }, {
        description: "E-Mail",
        name: "sekretariat@argetp21.de",
        link: ""
      }, {
        description: "Registergericht",
        name: "Dresden",
        link: ""
      }, {
        description: "Handelsregister",
        name: "HRB 45608",
        link: ""
      }, {
        description: "Steuernummer",
        name: "203/121/00550",
        link: ""
      }, {
        description: "Ust-ID",
        name: "DE 208113927",
        link: ""
      }
    ]
  })
});
